import React from "react";
import Link from "../UI/Link/Link";

function NavItem({ title, handleClickImage }) {
  function addShineEffect(id) {
    let section = document.getElementById(id);
    if (section) {
      section.classList.add("shine-text");
      console.log("section : ", section);

      setTimeout(() => {
        section.classList.remove("shine-text");
      }, 1000);
    }
  }
  if (title === "Home") {
    return (
      <div className="nav-item" onClick={handleClickImage}>
        {title}
      </div>
    );
  }

       /**  
      */
  if (title === "Suppliers Check") {
    return (
      <Link target="suppliers-check">
        <div
          className="nav-item"
          onClick={() => addShineEffect("suppliers-check")}
        >
        {title}
        </div>
      </Link>
    );
  }

  if (title === "Products Check") {
    return (
      <Link target="product-planning">
        {" "}
        <div
          className="nav-item"
          onClick={() => addShineEffect("product-planning")}
        >
          {title}
        </div>
      </Link>
    );
  }

  if (title === "Custom Doc Generating") {
    return (
      <Link target="custom-expert">
        {" "}
        <div
          className="nav-item"
          onClick={() => addShineEffect("custom-expert")}
        >
          {title}
        </div>
      </Link>
    );
  }

  return (
    <Link target="analytics-expert">
      {" "}
      <div
        className="nav-item"
        onClick={() => addShineEffect("analytics-expert")}
      >
        {title}
      </div>
    </Link>
  );
}

export default NavItem;
